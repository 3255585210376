import React, { Component } from "react";
import cx from 'classnames';
import Styles from '../assets/styles/borrowingCalculator.module.css';
import { StaticQuery, graphql } from 'gatsby'
import allKenticoCloudItemFunnelSectionBorrowingCalculator from "../../data/kentico/allKenticoCloudItemFunnelSectionBorrowingCalculator";

const DEFAULTS = {
    ASSUMPTIONS: {
        COST_PER_DEPENDENT: 313,
        INTEREST_RATE_PERIOD_MIN: 1,
        INTEREST_RATE_PERIOD_MAX: 5,
        INTEREST_RATE_BUFFER_ONE: 2.25,
        INTEREST_RATE_BUFFER_TWO: 7.25,
        INTEREST_RATE_RAW: 5.88,
        LIVING_EXPENSES_SINGLE_HEM_VALUE_MONTHLY: 1135,
        LIVING_EXPENSES_JOINT_HEM_VALUE_MONTHLY: 2076,
        SERVICEABILITY_EXISTING_HOMELOAN_REPAYMENTS_LOADING: 1.30,
        SERVICEABILITY_RENTAL_INCOME_SHADING: 0.2,
        SERVICEABILITY_CREDIT_CARD_EXPENSES_INDICATOR: 0.03,
        STAMP_DUTY: 0,
        GOVERNMENT_FEES: 500,
        SELLING_AGENT_FEE_PERCENTAGE: 0.025,
        CONVEYANCING_FEE: 2500,
        LAND_TRANSFER_REGISTRATION_FEE: 2000
    },
    GENERAL: {
        APPLICATION_FEE: 600,
        DEPENDENTS: "0",
        CALL_ME_ERROR_MESSAGE: "Please fill out the highlighted fields",
        EMAIL_ME_ERROR_MESSAGE: "Please fill out the highlighted fields",
        INCOME_MIN: 0,
        INCOME_MAX: 50000000,
        STAMP_DUTY_MAX: 100000000,
        INCOME_FREQUENCY_INDEX: "0", // select control model requires a string
        INTEREST_ONLY_MIN: 0,
        INTEREST_ONLY_MAX: 25,
        LOAN_TERM: 30,
        LOAN_TERM_MIN: 1,
        LOAN_TERM_MAX: 30,
        LOAN_TERM_MAX_INTEREST_ONLY: 4,
        NAB_PRODUCT: "0",  // select control model requires a string
        REPAYMENT_FREQUENCY_INDEX: "0", // select control model requires a string
        REPAYMENT_TYPE_INDEX: "0", // select control model requires a string
        STATE: "0", // select control model requires a string
        DISCHARGE_FEE: 350,
        OTHER_COSTS: 0 // other costs on equity calculator
    },
    BORROWING_POWER: {
        LOAN_TERM_MAX: 29,
        FIRST_HOME_BUYERS: "0", //select control model requires a string.
        CURRENT_HOME_LOAN: "0",
        JOINT_EXPENSES: 0,
        MARRIAGE_STATUS_INDEX: "0", // select control model requires a string
        NUMBER_OF_BORROWERS: "0", // select control model requires a string
        OTHER_COMBINED_NET_INCOME: 0, // 0 is one
        OTHER_COMBINED_NET_INCOME_FREQUENCY: "0", // select control model requires a string
        OTHER_COMBINED_RENTAL_INCOME_FREQUENCY: "0", // select control model requires a string
        PROPERTY_TYPES: "0", // select control model requires a string
        RESULTS_REPAYMENT_FREQUENCY_INDEX: "0",
        SECOND_SCENARIO_PROPERTY_MAX: 62500000,
        SECOND_SCENARIO_ERROR_MESSAGE: "Please enter a valid amount"
    },
    REPAYMENTS: {
        FASTER_REPAYMENTS_OPTIONS: "0",  // select control model requires a string
        NAB_PRODUCT_INDEX: "0",  // select control model requires a string
        EXTRA_REPAYMENTS_AMOUNT_MAX: 5000,
        INTEREST_ONLY_PERIOD: 1,
        CHOICE_PACKAGE: "0",
        REPAYMENT_FREQUENCY_INDEX: "0", // select control model requires a string
        REPAYMENT_TYPE: "0", // select control model requires a string
        PROPERTY_USE: "0",
        ERROR_MESSAGE: ""
    },
    SETUP_COSTS: {
        FIRST_HOME_BUYERS: "0",
        PROPERTY_TYPES: "0",
        NEW_OR_EXISTING_PROPERTY: 0,
        PROPERTY_MIN: 0,
        PROPERTY_MAX: 62500000,
        ERROR_MESSAGE: "",
        MORTGAGE_FEE: 0,
        TRANSFER_FEE: 0,
        TOTAL_SETUP_FEE: 0,
        OTHERITEM: "",
        OTHERITEMCOST: 0
    },
    INCREASE_LOAN: {
        ESTIMATED_PROPERTY_VALUE_MIN: 0,
        ESTIMATED_PROPERTY_VALUE_MAX: 9999999999,
        CURRENT_BALANCE_MIN: 0,
        CURRENT_BALANCE_MAX: 50000000,
        CURRENT_INTEREST_RATE_MIN: 0.1,
        CURRENT_INTEREST_RATE_MAX: 25,
        LOAN_TERM_REMAINING_MIN: 1,
        LOAN_TERM_REMAINING_MAX: 30,
        RESULTS_REPAYMENT_FREQUENCY_INDEX: "0", // select control model requires a string
        RENT_FOR_INCOME_FREQUENCY_INDEX: "3" // select control model requires a string
    },

    SCROLL_DELAY: 250,
    SCROLL_OFFSET: -80,
    TAB_DURATION: 250
};
const OPTIONS = {
    CHOICE_PACKAGE: [
        { label: "Yes", value: 0 },
        { label: "No", value: 1 }
    ],
    FASTER_REPAYMENTS_OPTIONS: [
        { label: "Extra repayments", value: 0 },
        { label: "Lump sum repayment", value: 1 },
        { label: "Average amount in offset account", value: 2 }
    ],
    FIRST_HOME_BUYERS: [
        { label: "Yes", value: "fh" },
        { label: "No", value: null }
    ],
    CURRENT_HOME_LOAN: [
        { label: "Yes", value: "cl" },
        { label: "No", value: null }
    ],
    INTEREST_ONLY_LOAN: [
        { label: "Yes", value: "il" },
        { label: "No", value: null }
    ],
    INCOME_FREQUENCIES: [
        { label: "Annually", value: 1, single: "year", short: "yr" },
        { label: "Monthly", value: 12, single: "month", short: "mth" },
        { label: "Fortnightly", value: 26, single: "fortnight", short: "ftn" },
        { label: "Weekly", value: 52, single: "week", short: "wk" }
    ],
    JOINT_EXPENSES: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" }
    ],
    REPAYMENT_FREQUENCIES: [
        { label: "Monthly", value: 12, single: "month", short: "mth" },
        { label: "Fortnightly", value: 26, single: "fortnight", short: "ftn" },
        { label: "Weekly", value: 52, single: "week", short: "wk" }
    ],
    REPAYMENT_FREQUENCIES_TWO: [
        { label: "Month", value: 12, single: "month", short: "mth" },
        { label: "Fortnight", value: 26, single: "fortnight", short: "ftn" },
        { label: "Week", value: 52, single: "week", short: "wk" }
    ],
    MARRIAGE_TYPES: [
        { label: "Single", value: 0 },
        { label: "Married", value: 1 },
        { label: "De facto", value: 2 },
        { label: "Other", value: 3 }
    ],
    NUMBER_OF_BORROWERS: [
        { label: "One", value: 1 },
        { label: "Two", value: 2 }
    ],
    REPAYMENT_TYPE: [
        { label: "Principal & interest", value: 0 },
        { label: "Interest only", value: 1 }
    ],
    PAY_OFF_FASTER_REPAYMENTS_OPTIONS: [
        { label: "Extra repayments", value: 0 },
        { label: "Lump sum repayment", value: 1 },
        { label: "Average amount in offset account", value: 2 }
    ],
    PROPERTY_USE: [
        { label: "No", value: 0 },
        { label: "Yes", value: 1 }
    ],
    PROPERTY_TYPES: [
        { label: "To live In", value: "ppr" },
        { label: "Investment", value: "i" }
    ],
    NEW_OR_EXISTING_PROPERTY: [
        { label: "New", value: 1 },
        { label: "Existing", value: 0 }
    ],
    STATES: [
        { label: "Choose", value: "" },
        { label: "ACT", value: "ACT" },
        { label: "New South Wales", value: "NSW" },
        { label: "Northern Territory", value: "NT" },
        { label: "Queensland", value: "QLD" },
        { label: "South Australia", value: "SA" },
        { label: "Tasmania", value: "TAS" },
        { label: "Victoria", value: "VIC" },
        { label: "Western Australia", value: "WA" }
    ],
    TOTAL_DEPENDANTS: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" }
    ],
    LONG_TERMS: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        { label: "29", value: "29" },
        { label: "30", value: "30" }
    ],
    RENTING_OUT_PROPERTY: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" }
    ],
};



class BorrowingCalculator extends Component {
    constructor(props) {
        super(props);
        //this.borrowCalContent = props.data.allKenticoCloudItemFunnelSectionBorrowingCalculator;
        this.borrowCalContent = allKenticoCloudItemFunnelSectionBorrowingCalculator;
        this.intrestrateDynamic = props.data.allHashchingDeal;
        console.log(this.intrestrateDynamic.edges[0].node.interest_rate);
        this.state = {
            noofborrow: 1,
            noofdependants: 0,
            purchaseValue: 0,
            netincomeval: '50,000',
            netincome: 0,
            netincomeval2: 0,
            netincome2: 0,
            otherincomeval: 0,
            otherincome: 0,
            otherrentalincomeval: 0,
            otherrentalincome: 0,
            monthlylvingexp: '2,000',
            creditcardlimits: 0,
            othermonthlyloanrep: 0,
            intrate: this.intrestrateDynamic.edges[0].node.interest_rate,
            loanterm: 30,
            monrep: 0,
            noofborrowtext: 'Salary Income',
            OtherIncometext: 'Other Income',
            RentalIncometext: 'Rental Income',
            borrowingAmount: '',
            monthlyRepayment: '',
            totIntAmtPayableBorrow: '',
            purchaseValueformatted: '',
            OneBorrowInSelected: 'active',
            TwoBorrowInSelected: '',
            borrowingSalaryIncomeError: false,
            livingExpenseError: false,
            interateRateeError: false

        };

        this.CalculateandUpdate = this.CalculateandUpdate.bind(this)
        this.myRef = React.createRef()
    }
    componentWillMount() {
        this.CalculateandUpdate()
    }

    HEM(dependants, isSingleBorrower, income) {
        var hem1 = [
            [0, 1004, 1323, 1651, 1793, 1936, 2079, 2221, 2364, 2507, 2649],
            [21988, 1065, 1383, 1711, 1853, 1996, 2139, 2282, 2424, 2567, 2710],
            [26324, 1044, 1362, 1690, 1833, 1976, 2118, 2261, 2404, 2546, 2689],
            [30928, 1065, 1383, 1711, 1854, 1996, 2139, 2282, 2425, 2567, 2710],
            [34994, 1178, 1496, 1824, 1967, 2110, 2252, 2395, 2538, 2681, 2823],
            [38786, 1242, 1560, 1888, 2031, 2173, 2316, 2459, 2602, 2744, 2887],
            [42578, 1361, 1679, 2007, 2150, 2293, 2435, 2578, 2721, 2863, 3006],
            [46370, 1347, 1666, 1993, 2136, 2279, 2422, 2564, 2707, 2850, 2992],
            [53953, 1517, 1835, 2163, 2305, 2448, 2591, 2734, 2876, 3019, 3162],
            [61490, 1692, 2010, 2338, 2481, 2624, 2767, 2909, 3052, 3195, 3337],
            [68553, 1695, 2013, 2341, 2484, 2626, 2769, 2912, 3054, 3197, 3340],
            [75615, 1775, 2093, 2421, 2564, 2706, 2849, 2992, 3134, 3277, 3420],
            [82677, 1948, 2266, 2594, 2736, 2879, 3022, 3165, 3307, 3450, 3593],
            [96802, 2064, 2382, 2710, 2852, 2995, 3138, 3280, 3423, 3566, 3709],
            [110927, 2223, 2541, 2869, 3012, 3154, 3297, 3440, 3582, 3725, 3868],
            [124527, 2310, 2628, 2956, 3099, 3242, 3385, 3527, 3670, 3813, 3955],
            [148147, 2481, 2799, 3127, 3269, 3412, 3555, 3698, 3840, 3983, 4126],
            [183053, 2683, 3001, 3329, 3471, 3614, 3757, 3900, 4042, 4185, 4328],
            [234053, 3041, 3359, 3687, 3829, 3972, 4115, 4257, 4400, 4543, 4685]
        ];

        var hem2 = [
            [0, 1680, 1888, 2285, 2542, 2800, 3058, 3316, 3574, 3832, 4090],
            [21988, 1740, 1948, 2345, 2603, 2861, 3119, 3376, 3634, 3892, 4150],
            [26324, 1719, 1928, 2324, 2582, 2840, 3098, 3356, 3614, 3872, 4130],
            [30928, 1740, 1949, 2345, 2603, 2861, 3119, 3377, 3635, 3893, 4150],
            [34994, 1854, 2062, 2458, 2716, 2974, 3232, 3490, 3748, 4006, 4264],
            [38786, 1917, 2126, 2522, 2780, 3038, 3296, 3554, 3812, 4070, 4327],
            [42578, 2037, 2245, 2641, 2899, 3157, 3415, 3673, 3931, 4189, 4447],
            [46370, 2023, 2231, 2627, 2885, 3143, 3401, 3659, 3917, 4175, 4433],
            [53953, 2192, 2400, 2797, 3055, 3313, 3571, 3828, 4086, 4344, 4602],
            [61490, 2368, 2576, 2972, 3230, 3488, 3746, 4004, 4262, 4520, 4778],
            [68553, 2370, 2579, 2975, 3233, 3491, 3749, 4007, 4265, 4522, 4780],
            [75615, 2450, 2658, 3055, 3313, 3571, 3829, 4087, 4344, 4602, 4860],
            [82677, 2623, 2831, 3228, 3486, 3744, 4002, 4259, 4517, 4775, 5033],
            [96802, 2739, 2947, 3344, 3602, 3859, 4117, 4375, 4633, 4891, 5149],
            [110927, 2898, 3106, 3503, 3761, 4019, 4277, 4534, 4792, 5050, 5308],
            [124527, 2986, 3194, 3590, 3848, 4106, 4364, 4622, 4880, 5138, 5396],
            [148147, 3156, 3364, 3761, 4019, 4277, 4535, 4792, 5050, 5308, 5566],
            [183053, 3358, 3566, 3963, 4221, 4479, 4737, 4994, 5252, 5510, 5768],
            [234053, 3716, 3924, 4321, 4579, 4836, 5094, 5352, 5610, 5868, 6126]
        ];

        var i = 0,

            // Given the width of the tables given, decide what is the maximum number of dependants
            maxDependants = hem1[0].length - 2,

            // Given the number of dependants, decide what column should we look at
            col = dependants > maxDependants ? maxDependants + 1 : dependants + 1,

            // Given the marital status, decide what hem table to use
            table = isSingleBorrower ? hem1 : hem2;

        while (i < table.length && income > table[i][0]) {
            i++;
        }

        //reduce i by 1, but prevent it from being falsy
        i = i && i - 1 || 0;

        return table[i][col];
    }
    calculateIntrestRate(repamount, boramount, frequency) {
        var totalMon = this.state.loanterm * frequency;
        var totInt = totalMon * repamount;
        var totIntPayable = totInt - boramount;

        return totIntPayable;
    };
    getRepaymentsAmount(maximumBorrowingAmount, repaymentFrequency) {
        var repaymentFrequencyVal = this.getFrequencyValue(repaymentFrequency, OPTIONS.REPAYMENT_FREQUENCIES);
        var getDefaultInterestRate = (this.state.intrate / 100);
        var interestRatePerFrequency = getDefaultInterestRate / repaymentFrequencyVal;
        var A = maximumBorrowingAmount; // Loan Amount
        var N = repaymentFrequencyVal * this.state.loanterm;
        var repaymentsAmount = (interestRatePerFrequency * A) / (1 - Math.pow((1 + interestRatePerFrequency), -N));
        if (repaymentsAmount < 1 || isNaN(repaymentsAmount)) {

            return 0;
        }

        return Math.round(repaymentsAmount);
    }
    newGetRepaymentsAmount(amount, loanTrm, intRate) {
        var getDefaultInterestRate = (intRate / 100);
        var interestRatePerFrequency = getDefaultInterestRate / 12;
        var A = amount;
        var N = 12 * loanTrm;
        var newRepaymentsAmount = (interestRatePerFrequency * A) / (1 - Math.pow((1 + interestRatePerFrequency), -N));
        if (newRepaymentsAmount < 1 || isNaN(newRepaymentsAmount)) {
            return 0;
        }
        return Math.round(newRepaymentsAmount);
    }
    formatNumberWithCommas(value) {
        var formattedNumber = value.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(formattedNumber))
            formattedNumber = formattedNumber.replace(pattern, "$1,$2");

        return formattedNumber;
    };
    formatShortCurrencyAmount(amount) {
        var formattedAmount = 0;
        var rounded = 0;

        if (amount < 10000) {
            rounded = Math.floor(amount);
            formattedAmount = this.formatNumberWithCommas(rounded);
        } else if (amount >= 10000 && amount < 100000) {
            rounded = parseFloat((amount / 100), 10);
            rounded = Math.floor(rounded);
            rounded = rounded / 10;
            formattedAmount = rounded + "k";
        } else if (amount >= 100000 && amount < 1000000) {
            rounded = parseFloat((amount / 1000), 10);
            rounded = Math.round(rounded);
            formattedAmount = rounded + "k";
        } else if (amount >= 1000000 && amount < 10000000) {
            rounded = parseInt(amount / 100000);
            rounded = Math.floor(rounded);
            rounded = rounded / 10;
            formattedAmount = rounded + "m";
        } else if (amount >= 10000000) {
            rounded = amount / 100000;
            rounded = Math.floor(rounded);
            rounded = rounded / 10;
            formattedAmount = rounded + "m";
        }
        formattedAmount = '$' + formattedAmount;

        return formattedAmount;
    };
    getDefaultBorrowingPowerInterestRate(interestRate) {
        var userInterestRate = parseFloat(interestRate);
        var interestRate = Math.max((userInterestRate + DEFAULTS.ASSUMPTIONS.INTEREST_RATE_BUFFER_ONE), DEFAULTS.ASSUMPTIONS.INTEREST_RATE_BUFFER_TWO);

        return interestRate / 100;
    };
    getBorrowingAmount(totalMonthlyNetIncome, frequency, format) {
        var DefaultBorrowingPowerInterestRate = this.getDefaultBorrowingPowerInterestRate(this.state.intrate);
        var repaymentsPerPeriod = totalMonthlyNetIncome;
        var maximumBorrowingAmount = repaymentsPerPeriod * ((1 - Math.pow((1 + (DefaultBorrowingPowerInterestRate / 12)), (-12 * this.state.loanterm))) / (DefaultBorrowingPowerInterestRate / 12));
        var maximumBorrowingAmountRounded = 0;
        var rounded = 0;

        if (maximumBorrowingAmount <= 1000000) {
            rounded = parseInt((maximumBorrowingAmount / 1000), 10) * 1000; maximumBorrowingAmountRounded = Math.floor(rounded);
        } else {
            rounded = parseInt((maximumBorrowingAmount / 10000), 10) * 10000; maximumBorrowingAmountRounded = Math.floor(rounded);
        }
        if (maximumBorrowingAmountRounded < 1) { return 0; }
        if (maximumBorrowingAmountRounded > DEFAULTS.GENERAL.INCOME_MAX) { return DEFAULTS.GENERAL.INCOME_MAX; }
        if (format == "format") { return parseInt(maximumBorrowingAmountRounded, 10); }
        return maximumBorrowingAmountRounded;
    }

    convertToMonth(value, frequency_index, frequency_values) {
        var monthValue;
        var frequency = frequency_values[parseInt(frequency_index, 10)].label.toLowerCase();

        switch (frequency) {
            case "week": monthValue = (value * 52) / 12; break;
            case "weekly": monthValue = (value * 52) / 12; break;
            case "fortnight": monthValue = (value * 26) / 12; break;
            case "fortnightly": monthValue = (value * 26) / 12; break;
            case "month": monthValue = value; break;
            case "monthly": monthValue = value; break;
            case "annual": monthValue = value / 12; break;
            case "annually": monthValue = value / 12; break;
            default: monthValue = value;
        }

        return monthValue;
    };

    getFrequencyValue(frequency_index, frequency_values) {
        var value;
        var frequency = frequency_values[frequency_index].label.toLowerCase();
        switch (frequency) {
            case "week": value = 52; break;
            case "weekly": value = 52; break;
            case "fortnight": value = 26; break;
            case "fortnightly": value = 26; break;
            case "month": value = 12; break;
            case "monthly": value = 12; break;
            default: return value;
        }

        return value;
    };

    getTotalMonthlyIncome() {

        if (this.state.netincomeval.toString().indexOf(',') > -1) {
            var netincomeval = this.state.netincomeval.replace(/,/g, "")
        }
        else {
            var netincomeval = this.state.netincomeval;
        }


        if (this.state.netincomeval2.toString().indexOf(',') > -1) {
            var netincomeval2 = this.state.netincomeval2.replace(/,/g, "")
        }
        else {
            var netincomeval2 = this.state.netincomeval2;
        }

        if (this.state.otherincomeval.toString().indexOf(',') > -1) {
            var otherincomeval = this.state.otherincomeval.replace(/,/g, "")
        }
        else {
            var otherincomeval = this.state.otherincomeval;
        }

        if (this.state.otherrentalincomeval.toString().indexOf(',') > -1) {
            var otherrentalincomeval = this.state.otherrentalincomeval.replace(/,/g, "")
        }
        else {
            var otherrentalincomeval = this.state.otherrentalincomeval;
        }

        var totalMont1 = this.convertToMonth(netincomeval, this.state.netincome, OPTIONS.INCOME_FREQUENCIES);
        var totalMont2 = this.convertToMonth(netincomeval2, this.state.netincome2, OPTIONS.INCOME_FREQUENCIES);
        var totalMont3 = this.convertToMonth(otherincomeval, this.state.otherincome, OPTIONS.INCOME_FREQUENCIES) * 0.8;
        var totalMont4 = this.convertToMonth(otherrentalincomeval, this.state.otherrentalincome, OPTIONS.INCOME_FREQUENCIES) * 0.8;
        var totalMonthlyIncome = parseFloat(totalMont1) + parseFloat(totalMont2) + parseFloat(totalMont3) + parseFloat(totalMont4);

        return totalMonthlyIncome;
    }

    getNetMonthlyIncome() {

        if (this.state.netincomeval.toString().indexOf(',') > -1) {
            var netincomeval = this.state.netincomeval.replace(/,/g, "")
        }
        else {
            var netincomeval = this.state.netincomeval;
        }

        if (this.state.netincomeval2.toString().indexOf(',') > -1) {
            var netincomeval2 = this.state.netincomeval2.replace(/,/g, "")
        }
        else {
            var netincomeval2 = this.state.netincomeval2;
        }

        var tot1 = this.convertToMonth(netincomeval, this.state.netincome, OPTIONS.INCOME_FREQUENCIES);
        var tot2 = this.convertToMonth(netincomeval2, this.state.netincome2, OPTIONS.INCOME_FREQUENCIES);
        var netMonthlyIncome = parseFloat(tot1) + parseFloat(tot2);

        return netMonthlyIncome;
    }


    getTotalMonthlyExpenses() {

        var isSingleBorrower = this.state.noofborrow == 1;
        var dependantsValue = parseInt(this.state.noofdependants);
        var HEM_VALUE = this.HEM(dependantsValue, isSingleBorrower, (this.getNetMonthlyIncome() * 12));
        if (this.state.monthlylvingexp.toString().indexOf(',') > -1) {
            var monthlylvingexp = this.state.monthlylvingexp.replace(/,/g, "")
        }
        else {
            var monthlylvingexp = this.state.monthlylvingexp;
        }
        var totalGeneralMonthlyExpenses = monthlylvingexp;
        var totalDependentsCost = (this.state.noofdependants * DEFAULTS.ASSUMPTIONS.COST_PER_DEPENDENT);

        if (this.state.creditcardlimits.toString().indexOf(',') > -1) {
            var creditcardlimits = this.state.creditcardlimits.replace(/,/g, "")
        }
        else {
            var creditcardlimits = this.state.creditcardlimits;
        }
        var creditCardExpenses = creditcardlimits * DEFAULTS.ASSUMPTIONS.SERVICEABILITY_CREDIT_CARD_EXPENSES_INDICATOR;
        if (this.state.othermonthlyloanrep.toString().indexOf(',') > -1) {
            var othermonthlyloanrep = this.state.othermonthlyloanrep.replace(/,/g, "")
        }
        else {
            var othermonthlyloanrep = this.state.othermonthlyloanrep;
        }
        var totalMonthlyOtherRepayments = parseInt(othermonthlyloanrep) + parseInt(0);
        var totalMonthlyExpenses = Math.max(HEM_VALUE, parseInt(totalGeneralMonthlyExpenses)) + (parseInt(totalMonthlyOtherRepayments) + parseInt(creditCardExpenses));

        return totalMonthlyExpenses;
    }


    CalculateandUpdate(state_monrep = {}) {

        var totalMonthlyExpenses = this.getTotalMonthlyExpenses();
        var totalMonthlyIncome = this.getTotalMonthlyIncome();
        var totalMonthlyNetIncome = totalMonthlyIncome - totalMonthlyExpenses;
        if (state_monrep.monrep) {
            var repaymentFrequency = this.getFrequencyValue(state_monrep.monrep, OPTIONS.REPAYMENT_FREQUENCIES);
        }
        else {
            var repaymentFrequency = this.getFrequencyValue(this.state.monrep, OPTIONS.REPAYMENT_FREQUENCIES);
        }


        var maximumBorrowingAmount = this.getBorrowingAmount(totalMonthlyNetIncome, repaymentFrequency);
        var maximumBorrowingAmountFormatted = this.getBorrowingAmount(totalMonthlyNetIncome, repaymentFrequency, "format");
        if (this.state.purchaseValue.toString().indexOf(',') > -1) {

            var purchaseValueformatted = this.state.purchaseValue.replace(/,/g, "")
        }
        else {
            var purchaseValueformatted = this.state.purchaseValue;
        }
        this.state.purchaseValueformatted = this.formatShortCurrencyAmount(purchaseValueformatted);
        this.state.borrowingAmount = this.formatShortCurrencyAmount(maximumBorrowingAmount);

        if (state_monrep.monrep) {
            var monthlyRepayments = this.getRepaymentsAmount(maximumBorrowingAmount, state_monrep.monrep);
        }
        else {
            var monthlyRepayments = this.getRepaymentsAmount(maximumBorrowingAmount, this.state.monrep);
        }

        this.state.monthlyRepayment = this.formatShortCurrencyAmount(monthlyRepayments);
        var totalMonNew = this.state.loanterm * repaymentFrequency;
        var totIntNew = totalMonNew * monthlyRepayments;
        var latestAmtToPay = totalMonNew * monthlyRepayments;
        this.state.totIntAmtPayableBorrow = this.formatShortCurrencyAmount(latestAmtToPay);
        if (document.getElementById("borrowingAmountId")) {
            document.getElementById("borrowingAmountId").innerHTML = this.state.borrowingAmount;
        }

        if (document.getElementById("totIntAmtPayableBorrowId")) {
            document.getElementById("totIntAmtPayableBorrowId").innerHTML = this.state.totIntAmtPayableBorrow
        }
        if (document.getElementById("monthlyRepaymentId")) {
            document.getElementById("monthlyRepaymentId").innerHTML = this.state.monthlyRepayment
        }

    }
    onChange(e) {
        var value = e.target.value.trim().replace(/,/g, "");
        if (!this.validNumber(value)) {
            value = value.replace(/[^0-9]/g, '');
        }
    }

    validNumber(value) {
        var regex = /^[0-9,]+$/;
        return (!regex.test(value)) ? false : true;
    }
    onChangePurchaseValue(e) {
        var value = e.target.value.trim().replace(/,/g, "");
        if (!this.validNumber(value)) {
            value = value.replace(/[^0-9]/g, '');
        }

        this.setState({
            purchaseValue: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        });
    }

    onChangeSalaryIncomeValue(e) {
        var value = e.target.value.trim().replace(/,/g, "");
        if (!this.validNumber(value)) {
            value = value.replace(/[^0-9]/g, '');
        }
        if (!value || value == 0) {
            this.setState({
                netincomeval: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                borrowingSalaryIncomeError: true
            });
        }
        else {
            this.setState({
                netincomeval: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                borrowingSalaryIncomeError: false
            });
        }

    }
    onChangeSalaryIncomeValue2(e) {
        var value = e.target.value.trim().replace(/,/g, "");
        if (!this.validNumber(value)) {
            value = value.replace(/[^0-9]/g, '');
        }

        this.setState({
            netincomeval2: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        });
    }
    onChangeOtherIncomeValue(e) {
        var value = e.target.value.trim().replace(/,/g, "");
        if (!this.validNumber(value)) {
            value = value.replace(/[^0-9]/g, '');
        }

        this.setState({
            otherincomeval: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        });
    }
    onChangeRentalIncomeValue(e) {
        var value = e.target.value.trim().replace(/,/g, "");
        if (!this.validNumber(value)) {
            value = value.replace(/[^0-9]/g, '');
        }

        this.setState({
            otherrentalincomeval: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        });
    }
    onChangeMonthlyLivingExpensesValue(e) {
        var value = e.target.value.trim().replace(/,/g, "");
        if (!this.validNumber(value)) {
            value = value.replace(/[^0-9]/g, '');
        }
        if (!value || value == 0) {
            this.setState({
                monthlylvingexp: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                livingExpenseError: true
            });
        }
        else {
            this.setState({
                monthlylvingexp: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                livingExpenseError: false
            });
        }


    }
    onChangeCerditLimitValue(e) {
        var value = e.target.value.trim().replace(/,/g, "");
        if (!this.validNumber(value)) {
            value = value.replace(/[^0-9]/g, '');
        }

        this.setState({
            creditcardlimits: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        });
    }
    onChangeMonthlyLoanRepayments(e) {
        var value = e.target.value.trim().replace(/,/g, "");
        if (!this.validNumber(value)) {
            value = value.replace(/[^0-9]/g, '');
        }

        this.setState({
            othermonthlyloanrep: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        });
    }
    onChangeNoOfBorrowers(e) {
        var noofborrow = e.target.value;
        if (noofborrow == 1) {
            this.setState({
                noofborrowtext: this.borrowCalContent.edges[0].node.elements.label_yourincometext2.value,
                noofborrow: 1,
                OtherIncometext: this.borrowCalContent.edges[0].node.elements.label_yourincometext5.value,
                RentalIncometext: this.borrowCalContent.edges[0].node.elements.label_yourincometext7.value,
                netincomeval2: 0,
                netincome2: 0,
                OneBorrowInSelected: 'active',
                TwoBorrowInSelected: ''

            });
        }
        else {
            this.setState({
                noofborrowtext: this.borrowCalContent.edges[0].node.elements.label_yourincometext3.value,
                noofborrow: 2,
                OtherIncometext: this.borrowCalContent.edges[0].node.elements.label_yourincometext6.value,
                RentalIncometext: this.borrowCalContent.edges[0].node.elements.label_yourincometext8.value,
                OneBorrowInSelected: '',
                TwoBorrowInSelected: 'active'
            });
        }

    }
    onChangeNoOfDependants(e) {
        var hnoofdependents = e.target.value;
        this.setState({
            noofdependants: hnoofdependents
        });
    }
    OnChangeSalaryIncomeTerm(e) {
        var salincometerm = e.target.value;
        this.setState({
            netincome: salincometerm
        });
    }
    OnChangeSalaryIncomeTerm2(e) {
        var salincometerm2 = e.target.value;
        this.setState({
            netincome2: salincometerm2
        });


    }
    OnChangeOtherIncomeTerm(e) {
        var otherincometerm = e.target.value;
        this.setState({
            otherincome: otherincometerm
        });

    }
    OnChangeRentalIncomeTerm(e) {
        var rentalincometerm = e.target.value;
        this.setState({
            otherrentalincome: rentalincometerm
        });

    }
    OnchangeLoanTerm(e) {
        var loanterm = e.target.value;
        this.setState({
            loanterm: loanterm
        });

    }
    OnchangeInterestRate(e) {
        var interestrate = e.target.value;
        if (!interestrate || interestrate == 0) {
            this.setState({
                intrate: interestrate,
                interateRateeError: true
            })
        }
        else {
            this.setState({
                intrate: interestrate,
                interateRateeError: false
            })

        }

    }
    OnchangeMonthlyRepayments(e) {
        var monthlyrepayment = e.target.value;
        this.setState({
            monrep: monthlyrepayment
        })
        this.CalculateandUpdate({
            monrep: monthlyrepayment
        })
    }

    OptionalComponent(value) {
        if (value == 2) {
            return (
                <div className={cx('form-group col-sm-12', Styles.formGroup)}>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <label className={Styles.sectionHeding}>{this.borrowCalContent.edges[0].node.elements.label_yourincometext4.value}</label>
                            <div className={cx('input-group', Styles.inputGroup)}>
                                <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                    <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                </div>
                                <input type="text" className={cx('form-control', Styles.formControl)} placeholder="0" value={this.state.netincomeval2} onChange={this.onChangeSalaryIncomeValue2.bind(this)} />
                            </div>
                        </div>
                        <div className={'col-md-6'}>
                            <label className={cx(Styles.sectionHeding, Styles.blanksectionHeding)}>&nbsp;</label>
                            <select className={cx('form-control', Styles.formControl, Styles.selectItem)} value={this.state.netincome2} onChange={this.OnChangeSalaryIncomeTerm2.bind(this)} >
                                <option value="0">Annually</option>
                                <option value="1">Monthly</option>
                                <option value="2">Fortnightly</option>
                                <option value="3">Weekly</option>
                            </select>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return ''
        }
    }
    scrollDivTop() {

        if (typeof window !== 'undefined') {
            // console.log(document.getElementById("netincomevalId").style.display) ;
            var netincomeerror = document.getElementById("netincomevalId").style.display;
            var monthlylvingexpIderror = document.getElementById("monthlylvingexpId").style.display;
            var intrateIderror = document.getElementById('intrateId').style.display;
            if (netincomeerror == 'block') {

                document.getElementById("borrowamountestimate").style.display = 'none';
                //document.getElementById("netincomevalId").scrollIntoView({block: 'center', behavior: 'smooth'});
                window.scroll({
                    top: 250,
                    behavior: 'smooth'
                });
            }
            else if (monthlylvingexpIderror == 'block') {


                document.getElementById("borrowamountestimate").style.display = 'none';
                //document.getElementById("monthlylvingexpId").scrollIntoView({block: 'center', behavior: 'smooth'});
                window.scroll({
                    top: 550,
                    behavior: 'smooth'
                });
            }
            else if (intrateIderror == 'block') {


                document.getElementById("borrowamountestimate").style.display = 'none';
                //document.getElementById("intrateId").scrollIntoView({block: 'center', behavior: 'smooth'});
                window.scroll({
                    top: 700,
                    behavior: 'smooth'
                });
            }
            else {


                document.getElementById("borrowamountestimate").style.display = 'block';
                // document.getElementById("borrowamountestimate").scrollIntoView({block: 'center', behavior: 'smooth'});
                window.scroll({
                    top: 1300,
                    behavior: 'smooth'
                });
            }

        }
    }
    render() {
        const BorrowingCalculatorContent = this.borrowCalContent.edges[0].node.elements
        const OneBorrowInSelected = this.state.OneBorrowInSelected;
        const TwoBorrowInSelected = this.state.TwoBorrowInSelected;
        const styleError1 = this.state.borrowingSalaryIncomeError ? { display: 'block' } : { display: 'none' }
        const styleError2 = this.state.livingExpenseError ? { display: 'block' } : { display: 'none' }
        const styleError3 = this.state.interateRateeError ? { display: 'block' } : { display: 'none' }

        return (




            <section className={Styles.BorrowingCalcSection}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={cx(Styles.BorrowingCalcInner, 'col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mr-auto ml-auto')}>
                            <div className={'row'}>
                                <div className={cx(Styles.BorrowingCalcultrContnt, Styles.card, 'card col-12')}>
                                    <div className={'row'}>
                                        <div className={cx(Styles.headings, 'col-12')}>
                                            <h1 className={Styles.pageTitle}>Borrowing Calculator</h1>
                                            <p className={Styles.pageSummry}>Your go-to tool to estimate how much you can borrow and plan your finances with confidence. Whether you're dreaming of a new home, a car, or a personal project, this calculator makes it easy to explore your borrowing power in seconds. Simply input your details, and let the calculator provide tailored insights to help you make informed decisions. Try it now to take the first step toward achieving your goals—it's fast, easy, and completely free!</p>
                                            {/* <PageTitle />
                            <PageSummry /> */}
                                        </div>
                                        <div className={cx(Styles.BorrowingCalcForm, 'col-12')}>
                                            <div className={'row'}>
                                                <div className={cx(Styles.borowerFields, 'col-12')}>
                                                    <div className={'row'}>
                                                        <div className={cx(Styles.formGroup, 'form-group col-md-6')}>
                                                            <label className={Styles.sectionHeding}>{BorrowingCalculatorContent.label_noofborrowers.value}</label>
                                                            {/* <div className={cx(Styles.btnGroup, 'btn-group')}>
                                           <label className={'btn btn-default'}>
                                           <input type="radio" name="borrower" value="1" onChange={this.onChangeNoOfBorrowers.bind(this)} /><span> 1 </span>
                                           </label>
                                           <label className={'btn btn-default'}>
                                           <input type="radio" name="borrower" value="2" onChange={this.onChangeNoOfBorrowers.bind(this)} /><span> 2 </span>
                                           </label>
                                            </div>*/}

                                                            <div className={cx(Styles.mobWidth, Styles.fieldBtns)}>
                                                                <div className={cx('btn-group', Styles.btnGroup)} role="group">

                                                                    <label className={cx('btn', Styles.btn, Styles[OneBorrowInSelected])}>
                                                                        <input type="radio" name="borrower" value="1" onChange={this.onChangeNoOfBorrowers.bind(this)} /><span> 1 </span>
                                                                    </label>

                                                                    <label className={cx('btn', Styles.btn, Styles[TwoBorrowInSelected])}>
                                                                        <input type="radio" name="borrower" value="2" onChange={this.onChangeNoOfBorrowers.bind(this)} /><span> 2 </span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className={cx(Styles.formGroup, 'form-group col-md-6')}>
                                                            <label className={Styles.sectionHeding}>{BorrowingCalculatorContent.label_noofdependents.value}</label>
                                                            <select className={cx('form-control', Styles.formControl, Styles.selectItem)} onChange={this.onChangeNoOfDependants.bind(this)}>
                                                                <option value="0">0</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*1111 */}
                                                <div className={cx(Styles.incomeFields, 'col-12')}>
                                                    <div className={'row'}>
                                                        <h3 className={cx(Styles.fieldHeading, 'col-12')}>Your Income</h3>
                                                        {/* 111 */}
                                                        <div className={cx('form-group col-sm-12', Styles.formGroup)}>
                                                            <div className={'row'}>
                                                                <div className={'col-md-6'}>
                                                                    <label className={Styles.sectionHeding}>{this.state.noofborrowtext}</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                            <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                        </div>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} placeholder="0" value={this.state.netincomeval} onChange={this.onChangeSalaryIncomeValue.bind(this)} />

                                                                    </div>
                                                                    <p id="netincomevalId" className={'error'} style={styleError1}>Please enter borrower salary income*</p>
                                                                </div>
                                                                <div className={'col-md-6'}>
                                                                    <label className={cx(Styles.sectionHeding, Styles.blanksectionHeding)}>&nbsp;</label>
                                                                    <select className={cx('form-control', Styles.formControl, Styles.selectItem)} onChange={this.OnChangeSalaryIncomeTerm.bind(this)}>
                                                                        <option value="0">Annually</option>
                                                                        <option value="1">Monthly</option>
                                                                        <option value="2">Fortnightly</option>
                                                                        <option value="3">Weekly</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.OptionalComponent(this.state.noofborrow)}

                                                        {/* 222 */}
                                                        <div className={cx('form-group col-sm-12', Styles.formGroup)}>
                                                            <div className={'row'}>
                                                                <div className={'col-md-6'}>
                                                                    <label className={Styles.sectionHeding}>{this.state.OtherIncometext}</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                            <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                        </div>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} placeholder="0" value={this.state.otherincomeval} onChange={this.onChangeOtherIncomeValue.bind(this)} />
                                                                    </div>
                                                                </div>
                                                                <div className={'col-md-6'}>
                                                                    <label className={cx(Styles.sectionHeding, Styles.blanksectionHeding)}>&nbsp;</label>
                                                                    <select className={cx('form-control', Styles.formControl, Styles.selectItem)} onChange={this.OnChangeOtherIncomeTerm.bind(this)}>
                                                                        <option value="0">Annually</option>
                                                                        <option value="1">Monthly</option>
                                                                        <option value="2">Fortnightly</option>
                                                                        <option value="3">Weekly</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* 333 */}
                                                        <div className={cx('form-group col-sm-12', Styles.formGroup)}>
                                                            <div className={'row'}>
                                                                <div className={'col-md-6'}>
                                                                    <label className={Styles.sectionHeding}>{this.state.RentalIncometext}</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                            <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                        </div>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} placeholder="0" value={this.state.otherrentalincomeval} onChange={this.onChangeRentalIncomeValue.bind(this)} />
                                                                    </div>
                                                                </div>
                                                                <div className={'col-md-6'}>
                                                                    <label className={cx(Styles.sectionHeding, Styles.blanksectionHeding)}>&nbsp;</label>
                                                                    <select className={cx('form-control', Styles.formControl, Styles.selectItem)} value={this.state.otherrentalincome} onChange={this.OnChangeRentalIncomeTerm.bind(this)}>
                                                                        <option value="0">Annually</option>
                                                                        <option value="1">Monthly</option>
                                                                        <option value="2">Fortnightly</option>
                                                                        <option value="3">Weekly</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* 333 */}
                                                    </div>
                                                </div>
                                                {/* end income fields */}
                                                <div className={cx(Styles.expensesFields, 'col-12')}>
                                                    <div className={'row'}>
                                                        <h3 className={cx(Styles.fieldHeading, 'col-12')}>{BorrowingCalculatorContent.label_yourexpenses.value}</h3>
                                                        {/* 111 */}
                                                        <div className={cx('form-group col-sm-12', Styles.formGroup)}>
                                                            <div className={'row'}>
                                                                <div className={cx('col-md-6', Styles.mobspace)}>
                                                                    <label className={Styles.sectionHeding}>{BorrowingCalculatorContent.label_yourexpensestext1.value}</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                            <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                        </div>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} placeholder="2000" value={this.state.monthlylvingexp} onChange={this.onChangeMonthlyLivingExpensesValue.bind(this)} />

                                                                    </div>
                                                                    <p id="monthlylvingexpId" className={'error'} style={styleError2}>Please enter your living expense*</p>
                                                                </div>
                                                                <div className={'col-md-6'}>
                                                                    <label className={Styles.sectionHeding}>{BorrowingCalculatorContent.label_yourexpensestext2.value}</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                            <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                        </div>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} placeholder="0" value={this.state.creditcardlimits} onChange={this.onChangeCerditLimitValue.bind(this)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* 222 */}
                                                        <div className={cx('form-group col-sm-12', Styles.formGroup)}>
                                                            <div className={'row'}>
                                                                <div className={'col-md-6'}>
                                                                    <label className={Styles.sectionHeding}>{BorrowingCalculatorContent.label_yourexpensestext3.value}</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                            <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                        </div>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} placeholder="0" value={this.state.othermonthlyloanrep} onChange={this.onChangeMonthlyLoanRepayments.bind(this)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* 333 */}
                                                    </div>
                                                </div>
                                                {/* end expnses fields */}
                                                <div className={cx(Styles.loanFields, 'col-12')}>
                                                    <div className={'row'}>
                                                        <h3 className={cx(Styles.fieldHeading, 'col-12')}>{BorrowingCalculatorContent.label_yourloan.value}</h3>
                                                        <div className={cx('form-group col-sm-12', Styles.formGroup)}>
                                                            <div className={'row'}>
                                                                <div className={cx('col-md-6', Styles.mobspace)}>
                                                                    <label className={Styles.sectionHeding}>{BorrowingCalculatorContent.label_yourloantext1.value}</label>
                                                                    <div className={cx('input-group', Styles.inputGroup, Styles.rightinputGroup)}>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} placeholder={this.intrestrateDynamic.edges[0].node.interest_rate} value={this.state.intrate} onChange={this.OnchangeInterestRate.bind(this)} />
                                                                        <div className={cx('input-group-append', Styles.inputGroupAppend)}>

                                                                            <span className={cx('input-group-text', Styles.inputGroupText)}>%</span>
                                                                        </div>
                                                                    </div>
                                                                    <p id="intrateId" className={'error'} style={styleError3}>Please enter interest rate*</p>
                                                                </div>
                                                                <div className={'col-md-6'}>
                                                                    <label className={Styles.sectionHeding}>{BorrowingCalculatorContent.label_yourloantext2.value}</label>
                                                                    <select className={cx('form-control', Styles.formControl, Styles.selectItem)} value={this.state.loanterm} onChange={this.OnchangeLoanTerm.bind(this)}>
                                                                        <option value="1">1 year</option>
                                                                        <option value="2">2 years</option>
                                                                        <option value="3">3 years</option>
                                                                        <option value="4">4 years</option>
                                                                        <option value="5">5 years</option>
                                                                        <option value="6">6 years</option>
                                                                        <option value="7">7 years</option>
                                                                        <option value="8">8 years</option>
                                                                        <option value="9">9 years</option>
                                                                        <option value="10">10 years</option>
                                                                        <option value="11">11 years</option>
                                                                        <option value="12">12 years</option>
                                                                        <option value="13">13 years</option>
                                                                        <option value="14">14 years</option>
                                                                        <option value="15">15 years</option>
                                                                        <option value="16">16 years</option>
                                                                        <option value="17">17 years</option>
                                                                        <option value="18">18 years</option>
                                                                        <option value="19">19 years</option>
                                                                        <option value="20">20 years</option>
                                                                        <option value="21">21 years</option>
                                                                        <option value="22">22 years</option>
                                                                        <option value="23">23 years</option>
                                                                        <option value="24">24 years</option>
                                                                        <option value="25">25 years</option>
                                                                        <option value="26">26 years</option>
                                                                        <option value="27">27 years</option>
                                                                        <option value="28">28 years</option>
                                                                        <option value="29">29 years</option>
                                                                        <option value="30">30 years</option>

                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* 222 */}
                                                    </div>
                                                </div>
                                                {/* end your loan fields */}
                                                <div className={cx(Styles.fieldsubmtBtn, 'col-12')}>
                                                    <a className={cx(Styles.caclculatebtn, 'blue-btn btn')} href="javascript:void(0)" onClick={() => { this.CalculateandUpdate(); this.scrollDivTop() }} >{BorrowingCalculatorContent.label_calculatebuttontext.value}</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end calculator fields */}
                                <div className={cx(Styles.estimtedresult, Styles.card, 'card col-12')} id="borrowamountestimate" style={{ display: 'none' }}>
                                    <div className={'row'}>
                                        <h3 className={cx(Styles.sectiontilte, 'col-12 text-center')}>
                                            {BorrowingCalculatorContent.label_estimate.value}
                                        </h3>
                                        <div className={cx(Styles.resultcontnt, 'col-12 text-center')}>
                                            <div className={'row'}>
                                                <div className={cx(Styles.resultvalues, 'col-md-4')}>
                                                    <h4><span dangerouslySetInnerHTML={{ __html: BorrowingCalculatorContent.label_estimatetext1.value }}></span></h4>
                                                    <h3 id="borrowingAmountId">{this.state.borrowingAmount}</h3>
                                                </div>
                                                <div className={cx(Styles.resultvalues, 'col-md-4')}>
                                                    <h4><span dangerouslySetInnerHTML={{ __html: BorrowingCalculatorContent.label_estimatetext3.value }}></span></h4>
                                                    <h3 id="totIntAmtPayableBorrowId">{this.state.totIntAmtPayableBorrow}</h3>
                                                </div>
                                                <div className={cx(Styles.resultvalues, 'col-md-4')}>
                                                    <h4>{BorrowingCalculatorContent.label_estimatetext4.value}</h4>
                                                    <h3 className={Styles.repymntValue}>
                                                        <span id="monthlyRepaymentId" className={Styles.repymntvaluetxt}>
                                                            {this.state.monthlyRepayment}
                                                        </span>

                                                        <select className={Styles.selectrepymnt} value={this.state.monrep} onChange={this.OnchangeMonthlyRepayments.bind(this)} >
                                                            <option value="0">Monthly</option>
                                                            <option value="1">Fortnightly</option>
                                                            <option value="2">Weekly</option>
                                                        </select>

                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx(Styles.valueRatioTxt, 'col-12 text-center')}>
                                            {BorrowingCalculatorContent.label_estimatetext.value}
                                        </div>
                                        <div className={cx(Styles.fieldsubmtBtn, 'text-center col-12')}>
                                            <a className={cx(Styles.continuebtn, 'blue-btn btn')} href="javascript://" onClick={(event) => {
                                                this.props.handleCalculatorClick();
                                            }}>{BorrowingCalculatorContent.label_estimatebuttontext.value}</a>
                                        </div>
                                    </div>
                                </div>
                                {/* end estimated result */}
                                {/*  <div className={cx(Styles.saveresultSection, Styles.card, 'card col-12')}>
                    <label>{BorrowingCalculatorContent.save_resultforlater[0].elements.label_text.value}</label>
                    <div className={cx(Styles.inputGroup, 'input-group')}>
                        <input type="email" className={cx(Styles.formControl, 'form-control')} placeholder={BorrowingCalculatorContent.save_resultforlater[0].elements.input_placeholder.value} name="email" />
                        <span className={cx(Styles.subscribenewsBtnMain, Styles.inputGroupBtn, 'input-group-btn')}>
                            <button className={cx(Styles.sendBtn, 'btn')} type="submit">
                               {BorrowingCalculatorContent.save_resultforlater[0].elements.button_text.value}
                            </button>
                        </span>
                    </div>
                </div> */}
                                {/* end saveresultSection */}
                            </div>
                        </div>
                        {/* BorrowingCalcInner end */}
                    </div>
                </div>
            </section>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={
            graphql`
        query {
  allHashchingDeal(
    sort: {
      fields: [interest_rate]
      order: [ASC]
    }
  ) {
    edges {
      node {
        interest_rate
       }
    }
  }
}
      `
        }
        render={(data) => (
            <BorrowingCalculator data={data} {...props} />
        )}
    />
)